<script setup>
import { ref } from 'vue';
import Impressum from '@/components/ImpressumComponent.vue';

const open = ref(false);
</script>

<template>
  <div class="cont-wrap">
    <div class="container-joflow">
      <h2 class="logo">joflow</h2>
    </div>
    <div class="footer-box">
      <div class="footer-text">
        <a @click="open = true" class="footer">Impressum</a>
      </div>
    </div>

    <div class="modal-wrapper" v-if="open">
      <div class="modal" v-if="open">
        <Impressum @close="open = false" />
      </div>
    </div>
  </div>
</template>

<style scoped>
@import 'assets/main.css';
.cont-wrap {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100dvh;
}

.container-joflow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80dvh;
  width: 100vw;
}

.footer-box {
  position: relative;
  bottom: 20px;
  left: 0;
  width: 100vw;
  height: 20vh;
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    bottom: 45px;
  }
}

.footer-text {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.modal-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  font-size: 15px;
  height: 100dvh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  z-index: 998;
  width: 70vw;
  height: 70vh;
  background: white;
  @media only screen and (max-width: 600px) {
    width: 100vw;
    height: 100svh;
  }
}
</style>
